<script setup lang="ts">
import RecipientHeaderGiftlyLogo from '@consumer/modules/layout/RecipientHeaderGiftlyLogo.vue'

defineProps<{
  title?: string | null
  canGoBack?: boolean
}>()

const emit = defineEmits<{(e: 'goBack'): void}>()
</script>

<template>
  <div class="h-65px lg:h-85px px-6px py-16px font-normal bg-white max-w-screen flex items-center justify-center">
    <div class="absolute left-24px top-20px lg:top-28px">
      <a v-show="canGoBack" class="cursor-pointer" @click="emit('goBack')">
        <GIcon
          name="new-caret-left"
          size="20px"
          color="grey-700"
          class="!w-20px !h-20px !lg:w-24px !h-24px"
        />
      </a>
    </div>
    <div class="giftly-logo bg-contain flex items-center h-full">
      <div id="header-title" class="lg:hidden">
        <slot :title="title">
          <h1 v-if="title" class="text-base">{{ title }}</h1>
          <RecipientHeaderGiftlyLogo v-else/>
        </slot>
      </div>
      <div class="hidden lg:block">
        <RecipientHeaderGiftlyLogo/>
      </div>
    </div>
  </div>
</template>
