<script setup lang="ts">
import { getYear } from 'date-fns'

const year = getYear(new Date())
</script>

<template>
  <div class="text-sm bg-grey-900 text-white">
    <GContainer class="py-6 lg:py-12 lg:pb-36">
      <div class="mb-6 flex justify-between items-center">
        <GIcon name="new-misc-giftly-logo" color="gold" style="height: 32px; width: 68px;"/>
      </div>
      <p>
        The Giftly Prepaid Gift is redeemed for money through the Giftly website.
        It is not issued, endorsed, or accepted by any third-party merchant (including any
        third-party merchant explicitly referenced in this Giftly Prepaid Gift), and is not
        covered by the CARD Act. Giftly Prepaid Gifts never expire and there are no inactivity fees.
        Please see the Giftly Prepaid Gift Agreement for the complete terms.
      </p>
      <p>The Giftly Prepaid Gift is issued by Sunrise Banks N.A., St. Paul, MN 55103, Member FDIC.</p>
      <p>Copyright © {{ year }} Giftly. All rights reserved.</p>
    </GContainer>
  </div>
</template>
