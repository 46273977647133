<script setup lang="ts">
import MyAccountApi from '@consumer/api/MyAccountApi'
import GInputField from '@consumer/components/GInputField.vue'

const props = defineProps<{
  username: string
  savedUsername: string
}>()

const username = ref(props.username)
const checkingUsername = ref(false)
const usernameAvailble = ref(false)
const usernameNotAvailable = ref(false)
const usernameInvalid = ref(false)

watch(() => props.savedUsername, () => {
  usernameAvailble.value = false
  usernameNotAvailable.value = false
  usernameInvalid.value = false
})

interface Emit {
  (e: 'update:username', value: string): void
  (e: 'validateUsername', value: boolean): void
}

async function changed () {
  emit('update:username', username.value)
  usernameAvailble.value = false
  usernameNotAvailable.value = false
  usernameInvalid.value = false

  if (username.value !== props.savedUsername) {
    checkingUsername.value = true
    const response = await MyAccountApi.checkUsername({ data: { username: username.value } })
    checkingUsername.value = false
    if (response.available) {
      usernameAvailble.value = true
    }
    else if (!response.valid) {
      usernameInvalid.value = true
    }
    else if (!response.available) {
      usernameNotAvailable.value = true
    }
    emit('validateUsername', response.available)
  }
}

const emit = defineEmits<Emit>()
</script>
<template>
  <div class="mt-10">
    <div class="text-xl font-semibold">@Giftly Username</div>
    <div class="text-grey-secondary">Used when creating & publishing gifts</div>

    <div class="flex mt-5 mb-2">
      <div class="flex-none font-semibold">Name on Giftly</div>
      <div style="flex-grow: 1;"/>
      <div
        v-if="checkingUsername"
        class="flex-none"
        data-testid="checking-username"
      >
        <GIcon name="spinner" size="16px" color="grey-500" class="animate-spin icon-spinner"/>
      </div>
      <div
        v-else-if="usernameAvailble"
        class="flex-none flex flex-row items-center"
        style="color: #40a648;"
        data-testid="username-available"
      >
        <GIcon name="new-check" class="inline-block mr-1" size="12px"/>
        Username available!
      </div>
      <div
        v-else-if="usernameNotAvailable"
        class="flex-none flex flex-row items-center"
        style="color: #f22222;"
        data-testid="username-not-available"
      >
        <GIcon name="new-x" class="inline-block mr-1" size="12px"/>
        Username taken
      </div>
      <div
        v-else-if="usernameInvalid"
        class="flex-none flex flex-row items-center"
        style="color: #f22222;"
        data-testid="username-not-available"
      >
        <GIcon name="new-x" class="inline-block mr-1" size="12px"/>
        Invalid username
      </div>
    </div>
    <GInputField
      v-model="username"
      @input="changed()"
    >
      <template #addonLeft>@</template>
    </GInputField>
  </div>
</template>
